
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '../features/theme/styles/globals.scss';
import '../features/theme/generated/theme.css';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import React from 'react';
import MainLayout from '../features/layout/components/main/MainLayout';
// @ts-ignore Generated files
import { body, heading, secondary } from '../features/theme/generated/fonts';
import wrapper from '../store/ssr/wrapper';
import Bugsnag from '../../bugsnag';
import AuthListener from '../features/auth/components/AuthListener';
import ErrorView from '../features/app/components/errorView/ErrorView';
import isAppWhiteLabeled from '../features/app/utils/isAppWhiteLabeled';
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: React.ReactElement) => React.ReactNode;
    getHTMLClassName?: () => string;
};
type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};
const ErrorBoundary = Bugsnag?.getPlugin('react')!.createErrorBoundary(React);
function App({ Component, ...rest }: AppPropsWithLayout) {
    const { store, props } = wrapper.useWrappedStore(rest);
    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout ?? (page => <MainLayout>{page}</MainLayout>);
    const cssVars = `
    :root {
      --font-heading-family: ${heading.style.fontFamily};
      --font-heading-weight: ${heading.style.fontWeight ?? 600};

      --font-body-family: ${body.style.fontFamily};
      --font-body-weight: ${body.style.fontWeight ?? 400};

      --font-secondary-family: ${secondary.style.fontFamily};
      --font-secondary-weight: ${secondary.style.fontWeight ?? 400};
    }
  `.replace(/\s/g, '');
    if (typeof window !== 'undefined') {
        // Not using vh units to prevent mobile browsers from resizing the viewport when the address bar is shown/hidden
        // Get viewport height and multiply it by 1% of window inner height to get a value for a vh unit
        const vh = window.innerHeight * 0.01;
        // Set --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        // Resize event to re-calculate
        window.addEventListener('resize', () => {
            const vh1 = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh1}px`);
        });
        /**
         * Enabled postMessage API for WL theme designer to communicate with the app
         */
        if (isAppWhiteLabeled() && process.env.NEXT_PUBLIC_ENVIRONMENT === 'qa2') {
            window.addEventListener('message', event => {
                if (event.data.type === 'CHANGE_CSS_VARIABLES') {
                    const root = document.documentElement;
                    Object.entries(event.data.payload).forEach(([key, value]) => {
                        root.style.setProperty(key, value as string);
                    });
                }
            });
        }
    }
    return (<ErrorBoundary FallbackComponent={ErrorView}>
      <Provider store={store}>
        {getLayout(<>
            <Head>
              {/* eslint-disable-next-line react/no-danger */}
              <style dangerouslySetInnerHTML={{ __html: cssVars }}/>
            </Head>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...props.pageProps}/>
            <AuthListener />
          </>)}
      </Provider>
    </ErrorBoundary>);
}
const __Next_Translate__Page__1926550c2aa__ = App;

    export default __appWithI18n(__Next_Translate__Page__1926550c2aa__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  